import * as React from "react"
import { firebaseInit } from "../../firebaseConfig"
import firebase from "firebase"
import PageLayout from "../../components/pageLayout"
import { Table , Badge, Space, Descriptions, Image, Statistic} from "antd"
import { Tabs } from 'antd';
import { Content } from "antd/lib/layout/layout"

const { TabPane } = Tabs;
const {Column} = Table
const ProductDetailPage = ({...props})  => {
    
    const  productId = props.params.id
    const [product, setProduct] = React.useState()
    const [productInventory, setProductInventory] = React.useState()

    React.useEffect(()=>{
        firebaseInit(firebase)
        firebase.firestore().collection("products").doc(productId).get().then( queryResults => {

            if(queryResults.exists)
            {
                let doc = queryResults.data()
                console.log(doc)
                setProduct(doc)
            }
        })
    },[])

    return(<PageLayout>
            {product ? 
            <Content className="bg-white" 
            style={{
              padding: 24,
              margin: 0, 
              minHeight: 280,
            }}>                
                <Space direction="vertical" className="w-full">
                    <div className="flex justify-between">
                        <div className="flex">   
                            <div className="mr-4">
                                <img src={product.thumbail} className="w-20 h-20 rounded-full"></img>
                            </div>                             
                            <div>
                                <h1>{product.title}</h1>
                                <div className="text-sm italic text-gray-400">SKU: {product.sku}</div>
                                <Badge text={product.availability} status={`${product.availability.toLowerCase() ==="disponible" ? "success" : "error"}`}></Badge>
                                <div>                            
                                    <div>
                                        <a href={product.sourceUrl}>{product.sourceUrl}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Statistic title="Precio" value={product.price}></Statistic>
                        <Statistic title="Precio sugerido" value={product.retailPrice}></Statistic>
                        <Statistic title="Diferencia" value={product.retailPrice - product.price}></Statistic>
                        <Statistic title="Margen" value={`${(((product.retailPrice - product.price) / product.retailPrice)* 100).toFixed(2)}%`}></Statistic>
                    </div>
                    <Descriptions bordered>
                        <Descriptions.Item label="Marca">{product.brand}</Descriptions.Item>
                        <Descriptions.Item label="Modelo">{product.model}</Descriptions.Item>
                        <Descriptions.Item label="Proveedor">{product.supplier}</Descriptions.Item>                        
                        <Descriptions.Item label="Departamento">{product.department}</Descriptions.Item>
                        <Descriptions.Item label="Categoría">{product.category}</Descriptions.Item>
                        <Descriptions.Item label="División">{product.divition}</Descriptions.Item>
                        <Descriptions.Item label="Subcategoría">{product.subcategory}</Descriptions.Item>
                        
                    </Descriptions>
                    <Image.PreviewGroup>
                        {product.images.map( image => <Image width={200} src={image.img} preview={image.thumb}></Image>)}
                    </Image.PreviewGroup>
                    <Tabs defaultActiveKey="attributes">
                            <TabPane tab="Atributos" key="attributes">
                                <Table dataSource={product.attributes}> 
                                    <Column title="ID" dataIndex="id"></Column>
                                    <Column title="Atributo" dataIndex="name"></Column>
                                    <Column dataIndex="value"></Column>
                                </Table>
                            </TabPane>
                            <TabPane tab="Existencias" key="2">
                                    Content of Tab Pane 2
                            </TabPane>
                        </Tabs>
                </Space>                
            </Content>
            :<div>
                Cargando...
            </div> }

    </PageLayout>)

}

const styles = {


}

export default ProductDetailPage 